<style lang="less">
  #appLoadingPage{
    font-family: 'Saira-Medium', 'Saira-Regular', 'Saira-SemiBold';
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .loadingView{
      font-size: 18px;
      color: #565758;
    }
  }
</style>

<template>
  <div id="appLoadingPage">
    <!-- 系统加载提示 -->
    <div class="loadingView">System Loading...</div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import Service from "../../../libs/service.js";
import { gup } from "../../../libs/validate.js";

export default {
  data() {
    return {};
  },
  methods: {
    init() {
      if (gup("v")) {
        //捕获url 地址栏token
        Cookies.set("sdms_token", gup("v"));
        this.getUerInfo();
      }
    },
    getUerInfo() {
      var that = this;
      Service.request("get", "/sys/user/info", "", function (dt) {
        if (dt.data.status == 1 && dt.data.roleId) {
          Cookies.set("access", dt.data.roleId);
          Cookies.set("user", dt.data.username);
          Cookies.set("status", dt.data.status);
          Cookies.set("userId", dt.data.userId);
          Cookies.set("companyId", dt.data.companyId);
          // 系统 记录 token 等信息 并跳转默认页
          
          // Here to determine whether it's guest user and it has only one project so that we directly bring the guest into his project
          
          if(dt.data.roleId==5) {
            // It's a guest. Determine whether to redirect to project directly
            Service.request(
              'get',
              '/sys/project/page?page=1&limit=2&sidx=project_id&order=desc&title=&template=&creatorId=&start=&end=',
              undefined,
              function(dt) {
                let projectList = dt.page.list;
                
                if(projectList.length == 1) {
                  that.toProjectWorkspace(projectList[0]);
                }
                else {
                  that.$router.push({
                    // name: "overview",
                    name: 'projects'
                  });
                }
              }
            );
          }
          else {
            that.$router.push({
              // name: "overview",
              name: 'projects'
            });
          }
        }
      });
    },
    toProjectWorkspace(project){
      let redirectName = 'projectoverview';
      switch(project.template) {
        case 'smart_site':
          redirectName = 'smartsite_projectoverview';
          break;
        case 'plan':
          redirectName = 'planviewer';
          break;
        case 'ai':
          redirectName = 'aioverview';
          break;
        case 'ai_detection':
          redirectName = 'teachable';
          break;
        case 'sdms':
        default:
          redirectName = 'projectoverview';
      }
      
      this.$router.push({
        name: redirectName,
        query:{ prjid: project.projectId }
      });
    }
  },
  mounted() {
    this.init();
  },
};
</script>

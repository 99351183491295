/**
 * 邮箱
 * @param {*} s
 */
export function isEmail(s) {
  return /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,5})$/.test(s)
}

/**
 * 特殊字符
 * @param {*} s
 */
export function isSpecial(s) {
  return /[~'<>#%^&*\[\]\{\}]/g.test(s)
}

/**
 * 非法字符
 * @param {*} s
 */
export function isIllegal(s) {
  return /\b(master|truncate|insert|select|delete|update|declare|alter|drop)\b/g.test(s)
}

/**
 * 手机号码
 * @param {*} s
 */
export function isMobile(s) {
  // return /^1[0-9]{10}$/.test(s)
  return true;
}

/**
 * 电话号码
 * @param {*} s
 */
export function isPhone(s) {
  // return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(s)
  return true;
}

/**
 * URL地址
 * @param {*} s
 */
export function isURL(s) {
  return /^http[s]?:\/\/.*/.test(s)
}

export function gup(name, url) {
  if (!url) url = location.href;
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regexS = "[\\?&]" + name + "=([^&#]*)";
  var regex = new RegExp(regexS);
  var results = regex.exec(url);
  regex = undefined;
  return results == null ? null : results[1];
}
